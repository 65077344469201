import React from "react";

export default function Nav() {
  return (
    <div className="nav">
      <div className="container">
        <div className=" d-flex flex-wrap justify-content-center justify-content-sm-start">
          <div className="nav-item">
            <a className="nav-link" href="#home">
              <p className="nav-text">Home</p>
            </a>
            <div className="highlight" />
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#businessConsulting">
              <p className="nav-text">Consulting</p>
            </a>
            <div className="highlight" />
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#dataAnalytics">
              <p className="nav-text">Analytics</p>
            </a>
            <div className="highlight" />
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#strategicConsulting">
              <p className="nav-text">Strategy</p>
            </a>
            <div className="highlight" />
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#digitalProducts">
              <p className="nav-text">Digital products</p>
            </a>
            <div className="highlight" />
          </div>
          <div className="nav-item">
            <a className="nav-link" href="#training">
              <p className="nav-text">Training and Support</p>
            </a>
            <div className="highlight" />
          </div>
        </div>
      </div>
      <div className="nav-line" />
    </div>
  );
}
