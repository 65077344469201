import "bootstrap/dist/js/bootstrap.bundle";

import Header from "./components/Header";
import Nav from "./components/Nav";
import Contact from "./layout/Contact";
import Footer from "./layout/Footer";
import Hero from "./layout/Hero";
import Services from "./layout/Services";

function App() {
  return (
    <div className="App">
      <Header />
      <Nav />
      <Hero />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
