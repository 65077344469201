import React from "react";
import Button from "../components/Button";
import ContactModal from "../components/ContactModal";

export default function Contact() {
  return (
    <div className="contact">
      <div className="container d-flex flex-column align-items-center">
        <div className="pb-5">
          <p className="heading text-center d-none d-lg-block">
            To learn more about how we can help your business thrive in today’s{" "}
            <br /> data-driven world, contact us today!
          </p>
        </div>
        <div className="pb-5 text-center d-lg-none">
          <p className="heading">
            To learn more about how we can help your business thrive in today’s data-driven world, contact us today!
          </p>
        </div>
        <div className="pt-3">
          <ContactModal
            openModal={<Button title="Contact us" type="primary" />}
          />
        </div>
      </div>
    </div>
  );
}
