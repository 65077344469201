import React from "react";
import CookiePolicyModal from "../components/CookiePolicyModal";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row align-items-lg-center align-items-start">
          <div className="col-lg-6 justify-content-center">
            <p className="footer-content">
              © 2023 / IBS Expert LTD / Address: 20, Farm Avenue, London,
              England, HA0 4UU.
            </p>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center">
                <PrivacyPolicyModal
                  openModal={<p className="footer-content">Privacy Policy</p>}
                />
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <CookiePolicyModal
                  openModal={<p className="footer-content">Cookie Policy</p>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
