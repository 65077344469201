import { HiX } from "react-icons/hi";

export default function ContactModal(props) {
  return (
    <div className="cookie">
      <a type="button" data-bs-toggle="modal" data-bs-target="#contactModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="contactModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable cookie-modal-dialog">
          <div className="modal-content">
            <div className="modal-header cookie-modal-header justify-content-end">
              <button
                type="button"
                className="cookie-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
            </div>
            <div className="modal-body cookie-modal-body">
              <div className="mb-3">
                <p className="title-services" style={{ textAlign: "center" }}>
                  How can we help you?
                </p>
              </div>
              <div>
                <p className="content mb-3">
                  If you have any questions or concerns, please don’t hesitate
                  to reach out to us via email. We’re here to help and want to
                  ensure that you have a seamless experience with our company.
                </p>
                <p className="content mb-3">
                  Our team is available to answer your inquiries 24 hours a day,
                  7 days a week. Whether you have a question about our products
                  or services, need assistance, or simply want to provide
                  feedback, we’re happy to hear from you.
                </p>
                <p className="content mb-3">
                  To contact us via email, please send a message to{" "}
                  <b>info@ibsexpert.co.uk</b> . We make it a priority to respond
                  to all emails within 24 hours, but typically, our team will
                  respond much sooner than that.
                </p>
                <p className="content mb-3">
                  When you reach out to us via email, please be sure to include
                  as much detail as possible so that we can address your inquiry
                  in the most efficient and effective manner. This includes your
                  name, contact information, and a brief description of your
                  issue or question.
                </p>
                <p className="content mb-3">
                  Rest assured that your privacy is important to us, and we will
                  never share your contact information or personal details with
                  third parties.
                </p>
                <p className="content mb-3">
                  Thank you for considering contacting us through email. We look
                  forward to hearing from you and providing you with the best
                  customer service possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
