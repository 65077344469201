import React from "react";
import Button from "../components/Button";
import ContactModal from "./ContactModal";

export default function Header() {
  return (
    <div className="container">
      <div className=" header d-flex justify-content-between">
        <p className="logo">IBS Expert</p>
        <ContactModal
          openModal={<Button title="Contact us" type={"outline"} />}
        />
      </div>
      <div className="header-line" />
    </div>
  );
}
