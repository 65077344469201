import { HiX } from "react-icons/hi";

export default function PrivacyPolicyModal(props) {
  return (
    <div className="cookie">
      <a type="button" data-bs-toggle="modal" data-bs-target="#privacyModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="privacyModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable cookie-modal-dialog">
          <div className="modal-content">
            <div className="modal-header cookie-modal-header justify-content-end">
              <button
                type="button"
                className="cookie-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
            </div>
            <div className="modal-body cookie-modal-body">
              <div className="mb-3">
                <p className="title-services" style={{ textAlign: "center" }}>
                  Privacy Policy for IBS EXPERT LTD
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  This Privacy Policy outlines the types of personal information
                  that IBS EXPERT LTD collects and how it is used, stored and
                  protected. By using IBS EXPERT LTD, you consent to the
                  collection and use of your personal information as described
                  in this Privacy Policy.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Information Collected</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  IBS EXPERT LTD collects the following information from its
                  users:
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  1. Personal information such as name, email address, phone
                  number, postal address, and other information voluntarily
                  provided by users during the registration or checkout process.
                </p>
                <p className="content">
                  2. Information about how users interact with the website,
                  including but not limited to browser type, IP address, access
                  times, and referring website addresses.
                </p>
                <p className="content">
                  3. Information about users’ location, device and browser, as
                  well as demographic information such as age, gender, and
                  interests may also be collected through the use of cookies,
                  web beacons, and other similar technologies.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Use of Information</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  IBS EXPERT LTD uses the collected information for the
                  following purposes:
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  1. To provide and personalize services to users, such as
                  fulfilling orders and processing payments.
                </p>
                <p className="content">
                  2. To improve the functionality and user experience of the
                  website.
                </p>
                <p className="content">
                  3. To communicate with users regarding their account, order
                  status, promotions, or other relevant information.
                </p>
                <p className="content">
                  4. To analyze trends and usage of the website, and to perform
                  research and analytics to improve our products and services.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Sharing of Information</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  IBS EXPERT LTD may share users’ personal information with
                  third-party service providers who perform services on our
                  behalf, such as payment processors, shipping carriers, or
                  marketing companies. We may also share personal information in
                  response to a legal request, such as a subpoena or court
                  order, or to protect our rights or the safety of our users.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Security of Information</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  IBS EXPERT LTD takes reasonable measures to protect users’
                  personal information from unauthorized access, theft, or
                  disclosure. We use SSL encryption technology to protect
                  sensitive information during transmission, and we store
                  personal information in secure databases with access controls
                  and other security measures.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Changes to this Privacy Policy</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  IBS EXPERT LTD reserves the right to modify this Privacy
                  Policy at any time, and any changes will be effective
                  immediately upon posting on the website. Users are encouraged
                  to review this Privacy Policy regularly to stay informed about
                  our data practices.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Contact Us</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at info@ibsexpert.co.uk.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">Last Updated: March 17, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
