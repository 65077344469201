import React from "react";
import search from "../assets/search.png";
import analytics from "../assets/analytics.png";
import flag from "../assets/flag.png";
import phone from "../assets/phone.png";
import chat from "../assets/chat.png";

export default function Services() {
  return (
    <div className="services">
      <div className="container">
        <div className="d-flex justify-content-center pt-md-5 pb-5 mb-md-5 pt-4">
          <p className="title-services text-center text-sm-start">
            Here are some of the services we offer
          </p>
        </div>
        <div className="row mb-lg-5">
          <div className="col-sm-6">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="businessConsulting"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">
                  Business Intelligence Consulting
                </p>
              </div>
              <div className="card-image-container">
                <img className="card-image" src={search} alt="searchImg" />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  We help companies identify key performance indicators (KPIs),
                  design dashboards and reports, and build data models that
                  provide actionable insights.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="dataAnalytics"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">Data Analytics</p>
              </div>
              <div className="card-image-container">
                <img
                  className="card-image"
                  src={analytics}
                  alt="analyticsImg"
                />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  We help businesses collect, clean, and analyze data to
                  identify patterns, trends, and opportunities. Our team is
                  proficient in statistical analysis, data visualization, and
                  machine learning.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="strategicConsulting"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">Strategic Consulting</p>
              </div>
              <div className="card-image-container">
                <img className="card-image" src={flag} alt="flagImg" />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  We help businesses develop long-term plans that align with
                  their vision, mission, and values. Our team provides guidance
                  on market research, competitive analysis, and growth
                  strategies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="digitalProducts"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">Digital products</p>
              </div>
              <div className="card-image-container">
                <img className="card-image" src={phone} alt="phoneImg" />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  Our digital products enable businesses to harness the full
                  potential of their data, drive insights, optimize operations,
                  and achieve growth in today’s data-driven economy.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-md-none d-lg-flex">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="training"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">Training and Support</p>
              </div>
              <div className="card-image-container">
                <img className="card-image" src={chat} alt="chatImg" />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  We offer training and support services to ensure that our
                  clients can use data to make informed decisions. We provide
                  training on data analysis, visualization, and dashboard
                  design.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-none d-md-flex d-lg-none">
          <div className="col-3"></div>
          <div className="col-6">
            <div
              className="card d-flex flex-column align-items-center mb-3 mb-lg-0"
              id="training"
            >
              <div className="card-heading-wrapper">
                <p className="heading text-center">Training and Support</p>
              </div>
              <div className="card-image-container">
                <img className="card-image" src={chat} alt="chatImg" />
              </div>
              <div className="card-content-wrapper">
                <p className="content text-center">
                  We offer training and support services to ensure that our
                  clients can use data to make informed decisions. We provide
                  training on data analysis, visualization, and dashboard
                  design.
                </p>
              </div>
            </div>
          </div>
          <div className="col-3"></div>
        </div>
      </div>
    </div>
  );
}
