import { HiX } from "react-icons/hi";

export default function CookiePolicyModal(props) {
  return (
    <div className="cookie">
      <a type="button" data-bs-toggle="modal" data-bs-target="#cookieModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="cookieModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable cookie-modal-dialog">
          <div className="modal-content">
            <div className="modal-header cookie-modal-header justify-content-end">
              <button
                type="button"
                className="cookie-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
            </div>
            <div className="modal-body cookie-modal-body">
              <div className="mb-3">
                <p className="title-services" style={{ textAlign: "center" }}>
                  Cookie Policy for https://ibsexpert.co.uk
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  This Cookie Policy explains how https://ibsexpert.co.uk uses
                  cookies and similar technologies to collect information about
                  visitors to our website.
                </p>
                <p className="content">
                  By using https://ibsexpert.co.uk, you consent to our use of
                  cookies as described in this Cookie Policy.{" "}
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>What are Cookies?</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  Cookies are small text files that are stored on a user’s
                  device when they visit a website. They are used to collect
                  information about the user’s browsing habits and preferences,
                  and can be used to personalize the user’s experience on the
                  website.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Types of Cookies Used</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  https://ibsexpert.co.uk uses the following types of cookies:
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  1. Necessary Cookies: These cookies are essential for the
                  website to function properly and cannot be turned off in our
                  system. They are usually set in response to actions made by
                  the user, such as logging in or filling out forms.
                </p>
                <p className="content">
                  2. Analytical/Performance Cookies: These cookies allow us to
                  collect information about how visitors use our website, such
                  as which pages are most frequently visited, and to improve the
                  performance of our website.
                </p>
                <p className="content">
                  3. Functionality Cookies: These cookies allow us to remember
                  choices made by users, such as language preference, and to
                  provide personalized features.
                </p>
                <p className="content">
                  4. Advertising/Targeting Cookies: These cookies are used to
                  deliver targeted advertising to users based on their browsing
                  behavior and interests.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Third-Party Cookies</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  https://ibsexpert.co.uk uses third-party cookies from service
                  providers such as Google Analytics and Facebook to collect
                  information about visitors to our website.
                </p>
                <p className="content">
                  These third-party cookies may be used to track users across
                  multiple websites and to deliver targeted advertising based on
                  their browsing behavior and interests.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Managing Cookies</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  Users can manage their cookie preferences through their
                  browser settings. Most browsers allow users to block or delete
                  cookies, or to opt-out of certain types of cookies. However,
                  please note that disabling cookies may affect the
                  functionality of our website.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Changes to this Cookie Policy</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  https://ibsexpert.co.uk reserves the right to modify this
                  Cookie Policy at any time, and any changes will be effective
                  immediately upon posting on the website. Users are encouraged
                  to review this Cookie Policy regularly to stay informed about
                  our use of cookies.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  <b>Contact Us</b>
                </p>
              </div>
              <div className="mb-3">
                <p className="content">
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at info@ibsexpert.co.uk.
                </p>
              </div>
              <div className="mb-3">
                <p className="content">Last Updated: March 17, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
