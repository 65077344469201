import Button from "../components/Button";
import graph from "../assets/graph.png";
import ContactModal from "../components/ContactModal";

export default function Hero() {
  return (
    <div className="container hero" id="home">
      <div className="row">
        <div className="col-md-7">
          <div className="mb-4 d-none d-lg-block">
            <p className="title-hero">
              Welcome to our <br /> Intelligence Business Solutions <br />
              company!
            </p>
          </div>
          <div className="mb-4 d-lg-none">
            <p className="title-hero">
              Welcome to our Intelligence Business Solutions company!
            </p>
          </div>
          <div className="mb-2 mb-md-5">
            <p className="content">
              We are a team of experts in business intelligence, data analytics,
              and strategic consulting. Our mission is to help businesses
              leverage the power of data to make informed decisions, optimize
              their operations, and stay ahead of the competition.
            </p>
          </div>
          <div className="d-flex d-none d-md-flex">
            <ContactModal
              openModal={<Button title="Contact us" type={"primary"} />}
            />
          </div>
        </div>
        <div className="col-md-5  d-flex justify-content-center align-items-center mt-5 mt-sm-0">
          <div className="hero-image-container">
            <img src={graph} className="hero-image" alt="graphImg" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center d-md-none mt-5">
        <ContactModal
          openModal={<Button title="Contact us" type={"primary"} />}
        />
      </div>
    </div>
  );
}
